import React from "react";
import { MDBIcon } from "mdb-react-ui-kit";

function Footer(props) {
  return (
    <>
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <span
              className={`mb-3 mb-md-0 text-${
                props.mode === "light" ? "dark" : "light"
              } `}
              style={{
                fontSize: "15px",
                fontFamily: "sans-serif",
                fontVariant: "small-caps",
                fontWeight: 600,
              }}
            >
              Made with{" "}
              <MDBIcon style={{ color: "red" }} fas icon="a-solid fa-heart" />{" "}
              in Goa
            </span>
          </div>

          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li
              className={`mb-3 ms-3 mb-md-0 text-${
                props.mode === "light" ? "dark" : "light"
              } `}
            >
              &copy; 2023{" "}
              <span
                style={{
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  fontVariant: "small-caps",
                  fontWeight: 600,
                }}
              >
                HinterScapes
              </span>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
}

export default Footer;
