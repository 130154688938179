import React from "react";

const about = (props) => {
  return (
    <div className={`text-${props.mode === "light" ? "dark" : "light"}`}>
      <p
        style={{
          marginTop: "10px",
          textAlign: "center",
          fontSize: "40px",
          fontFamily: "sans-serif",
          fontVariant: "small-caps",
          fontWeight: 600,
        }}
      >
        {" "}
        About Texti
      </p>
      <p
        style={{
          fontSize: "20px",
          fontFamily: "sans-serif",
          fontVariant: "small-caps",
        }}
      >
        Texti is an online text tools which is a collection of useful text
        processing utilities.Texti is a Text manipulating application where in a
        user can convert the given text into number of different output such as
        Uppercase, Lowercase, Camel Case, Sentence Case, Split text, Join text,
        Remove blank Lines, Alternate Case, Title Case, Reverse Text, Remove
        Duplicate Lines, Remove Duplicate Words, Remove Extra Space and many
        more basic text utilities.
      </p>
      <p
        style={{
          fontSize: "20px",
          fontFamily: "sans-serif",
          fontVariant: "small-caps",
        }}
      >
        Texti also provide some of the core NLP utilities such as Generating
        Random Text, Remove Stopwords, Correcting Grammar, Traslating English
        text to Hindi and vice versa, Finding the sentiment of the text using
        sentiment analysis, Movie name to Emoji, Mood to color, Extract Keywords
        and Generate Ideas from the given keywords.
      </p>
      <p
        style={{
          fontSize: "20px",
          fontFamily: "sans-serif",
          fontVariant: "small-caps",
        }}
      >
        All text utilities in texti are simple, free and easy to use. Just the
        utilities that work right in your browser. And all utilities work
        exactly the same way — load text, get result.
      </p>

      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4521565878522096"
          crossorigin="anonymous"
        ></script>

        <ins
          class="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-4521565878522096"
          data-ad-slot="9296471405"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};

export default about;
