import React, { useState } from "react";
import { Configuration, OpenAIApi } from "openai";

const initialState = {
  itinerary:
    "Enter Your Destination and Number of Days to get your Customized Itinerary",
  destination: "",
  days: "",
};

export default function Form(props) {
  const [itinerary, setItinerary] = useState(initialState.itinerary);
  const [destination, setDestination] = useState(initialState.destination);
  const [days, setDays] = useState(initialState.days);
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const OPENAI_API_KEY = "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";

  //using openAI
  const genItinerary = async () => {
    if (days === "" && destination === "") {
      setItinerary(
        "Enter Your Destination and Number of Days To get Customized Itinerary"
      );
      props.showAlert("Fill The Required Details", "danger");
    } else {
      setLoading(true);
      setItinerary("Building Itinerary! Please Wait");
      const configuration = new Configuration({
        apiKey: OPENAI_API_KEY,
      });
      const openai = new OpenAIApi(configuration);

      let basePrompt = `what is an in detail ideal itinerary w.r.t. what to do in the Morning, Afternoon, Evening and Night for ${days} days in ${destination} w.r.t. the distance factor ?`;
      const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: basePrompt,
        temperature: 0.6,
        max_tokens: 2550,
        top_p: 1.0,
        frequency_penalty: 1,
        presence_penalty: 1,
      });

      setItinerary(response.data.choices[0].text);
      setLoading(false);
      props.showAlert("Itinerary Generated", "success");
    }
  };

  const OnChangeDestination = (e) => {
    setDestination(e.target.value);
  };

  const OnChangeDays = (e) => {
    setDays(e.target.value);
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-12" style={{ textAlign: "center" }}>
            <a href="https://hinterscapes.com/">
              <img
                src="/hs.png"
                style={{ width: "270px", marginBottom: "25px" }}
                alt="logo"
                className="img-fluid"
              />
            </a>
            <h1 className={`text-${props.mode === "light" ? "dark" : "light"}`}>
              <span
                style={{
                  fontSize: "30px",
                  fontFamily: "sans-serif",
                  fontVariant: "small-caps",
                  fontWeight: 600,
                }}
              >
                Build Your Own Itinerary
              </span>
            </h1>
          </div>
        </div>

        <div className="mb-3 row">
          <div className="col-md-6 my-2">
            <textarea
              className="form-control"
              placeholder="Destination"
              //value={text}
              id="destination"
              onChange={OnChangeDestination}
              style={{
                backgroundColor: props.mode === "dark" ? "grey" : "white",
                color: props.mode === "dark" ? "white" : "black",
              }}
            ></textarea>
          </div>
          <div className="col-md-6 my-2">
            {" "}
            {color ? (
              <>
                <div
                  style={{
                    width: "400px",
                    height: "250px",
                    backgroundColor: color,
                  }}
                ></div>
              </>
            ) : (
              <>
                <textarea
                  className="form-control"
                  placeholder="Number of Days"
                  //value={text}
                  id="days"
                  onChange={OnChangeDays}
                  style={{
                    backgroundColor: props.mode === "dark" ? "grey" : "white",
                    color: props.mode === "dark" ? "white" : "black",
                  }}
                ></textarea>
              </>
            )}
          </div>
        </div>
        <button
          type="button"
          className={`btn btn-${
            props.mode === "dark" ? "dark" : "success"
          } my-2 mx-2`}
          onClick={genItinerary}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#1A2B48",
            color: "white",
          }}
        >
          GENERATE ITINEARARY
        </button>
        <div
          className={`container my-3 text-${
            props.mode === "light" ? "dark" : "light"
          }`}
        >
          <p
            style={{
              fontSize: "30px",
              fontFamily: "sans-serif",
              fontVariant: "small-caps",
              fontWeight: 600,
            }}
          >
            {" "}
            Your Itinerary
          </p>
          <p
            style={{
              fontSize: "20px",
              marginBottom: "170px",
            }}
          >
            {itinerary.split(".").map((sentence, index) => (
              <div
                style={{
                  marginBottom: "30px",
                }}
                key={index}
              >
                {sentence}
              </div>
            ))}
          </p>
        </div>
      </div>
    </>
  );
}
